/*
 * Lawbrokr
 *
 * Usage:
 * 1. Place <script src="//cdn.lawbrokr.com/js/latest/lawbrokr.min.js"></script> in the <head> (not async).
 * 2. Then call:
 *    <script>
 *      Lawbrokr.init({
 *        domain: 'lawfirm.lawbrokr.com',
 *        trackParams: ['utm_source','utm_medium','gclid','fbclid'] // optional
 *      });
 *    </script>
 *
 * The script will wait for DOMContentLoaded and then rewrite links
 * that match the domain/subdomain, appending stored query params.
 */
(function(global) {
  // Default parameters to track with comprehensive platform support
  const DEFAULT_TRACK_PARAMS = [
    // Standard UTM Parameters
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
    
    // Google - Enhanced Parameters
    'gclid',        // Google Ads Click ID
    'gbraid',       // Google Browser Reporting API ID
    'wbraid',       // Google Web Browser Reporting API ID
    'dclid',        // Google Display Click ID
    'gad_source',   // Google Ads Source
    'gad',          // Google Ads Parameter
    'gad_id',       // Google Ads ID
    'adgroupid',    // Google Ads Ad Group ID
    'glsid',        // Google Local Services ID
    'gmb_id',       // Google My Business ID
    'gmb_source',   // Google My Business Source
    'gbp_source',   // Google Business Profile Source
    'gls_source',   // Google Local Services Source
    'g_source',     // Generic Google Source
    'google_source', // Alternate Google Source
    'youtube_source', // YouTube
    'ytscid',         // YouTube click ID

    // Meta
    'fbclid',        // Facebook
    'fb_action_ids', // Facebook actions
    'fb_source',     // Facebook source
    'fb_ref',        // Facebook referral
    'igshid',        // Instagram
    'ig_source',     // Instagram source
    'th_source',     // Threads
    
    // Microsoft
    'msclkid',      // Microsoft Advertising
    'utm_msclkid',  // Microsoft Click ID
    
    // LinkedIn
    'trackingId',   // LinkedIn
    'li_fat_id',    // LinkedIn First-party
    'linkedin_share_id', // LinkedIn sharing
    'li_source',    // LinkedIn source
    
    // Twitter
    'twclid',
    'tw_source',
    'tw_ref',

    // TikTok
    'ttclid',       // TikTok
    'tt_source',    // TikTok source
    'tt_medium',    // TikTok medium
    'tt_content',   // TikTok content

    // Other social media platforms
    'ref_src',      // Reddit
    'ref_campaign', // Reddit campaign
    'reddit_source', // Reddit source
    'bsky_id',      // Bluesky
    
    // Other Platforms
    'vgo_ee',       // ActiveCampaign
    'ac_source',    // ActiveCampaign alternate
    'avvo_campaign', // Avvo
    'avvo_source',  // Avvo source
    'ltk_source',   // Linktree
    'yad_id',       // Yahoo
    'yahoo_source', // Yahoo source
    'yelp_source',  // Yelp
    'yelp_campaign' // Yelp campaign
  ];

  // Source mapping for UTM detection
  const SOURCE_MAPPING = {
    // Google - Enhanced Mapping
    'gclid': 'google',
    'gbraid': 'google',
    'wbraid': 'google',
    'dclid': 'google',
    'gad_source': 'google',
    'gad': 'google',
    'gad_id': 'google',
    'adgroupid': 'google',
    'g_source': 'google',
    'google_source': 'google',
    // Google Local Services
    'glsid': 'google_local_services',
    'gls_source': 'google_local_services',
    'lsig': 'google_local_services',
    // Google My Business
    'gmb_id': 'google_my_business',
    'gmb_source': 'google_my_business',
    'gbp_source': 'google_my_business', // Business Profile (new name for GMB)
    'ludocid': 'google_my_business',
    // YouTube
    'youtube_source': 'youtube',
    'ytscid': 'youtube',
    
    // Meta
    'fbclid': 'facebook',
    'fb_action_ids': 'facebook',
    'fb_source': 'facebook',
    'fb_ref': 'facebook',
    'igshid': 'instagram',
    'ig_source': 'instagram',
    'th_source': 'threads',
    
    // Microsoft
    'msclkid': 'bing',
    'utm_msclkid': 'bing',
    
    // LinkedIn
    'trackingId': 'linkedin',
    'li_fat_id': 'linkedin',
    'linkedin_share_id': 'linkedin',
    'li_source': 'linkedin',

    // Twitter
    'twclid': 'twitter',
    'tw_source': 'twitter',
    'tw_ref': 'twitter',

    // TikTok
    'ttclid': 'tiktok',
    'tt_source': 'tiktok',
    'tt_medium': 'tiktok',
    'tt_content': 'tiktok',

    // Other social media platforms
    'ref_src': 'reddit',
    'ref_campaign': 'reddit',
    'reddit_source': 'reddit',
    'bsky_id': 'bluesky',
    
    // Other Platforms
    'vgo_ee': 'activecampaign',
    'ac_source': 'activecampaign',
    'avvo_campaign': 'avvo',
    'avvo_source': 'avvo',
    'ltk_source': 'linktree',
    'yad_id': 'yahoo',
    'yahoo_source': 'yahoo',
    'yelp_source': 'yelp',
    'yelp_campaign': 'yelp'
  };

  // Default Lawbrokr config
  const DEFAULT_CONFIG = {
    domain: '*.lawbrokr.com',
    trackParams: DEFAULT_TRACK_PARAMS,
    trackAllParams: false,
    report_errors: true
  };

  // Global Lawbrokr object
  var Lawbrokr = {};

  // Internal config
  var config = {
    domain: '*.lawbrokr.com',
    trackParams: [],
    trackAllParams: false,
    report_errors: true
  };

  // Store everything under this key
  const STORAGE_KEY = 'utm_forwarder_params';

  // Cookie configuration 
  const COOKIE_CONFIG = {
    days: 30,
    path: '/',
    domain: window.location.hostname.split('.').slice(-2).join('.'),
    secure: window.location.protocol === 'https:',
    sameSite: 'Lax' 
  };

  // Global variables
  let isUpdatingLinks = false;
  let reportToHoneybadger = false;

  function addBreadcrumb(message, metadata = {}, category = 'custom') {
    if (!reportToHoneybadger || !window.Honeybadger) {
      return;
    }

    window.Honeybadger.addBreadcrumb(message, {
      metadata: metadata,
      category: category
    });
  }

  function reportError(message, error, context = {}) {
    console.error(message, error);
    
    try {
      if (reportToHoneybadger && window.Honeybadger) {
        addBreadcrumb('Error Context', {
          domain: config.domain,
          location: window.location.href,
          timestamp: new Date().toISOString(),
          ...context
        }, 'error');

        window.Honeybadger.notify(error, {
          message: message
       });
      }
    } catch (err) {
      console.error('Error reporting error to Honeybadger', err);
    }
  }

  // Test function for Honeybadger integration
  function testHoneybadger() {
    try {
      // Add test breadcrumbs
      addBreadcrumb('Test Started', {
        timestamp: Date.now()
      }, 'log');

      addBreadcrumb('Test Parameters', {
        randomValue: Math.random(),
        testType: 'integration'
      }, 'custom');

      // Throw a test error
      const testError = new Error('Test error from Lawbrokr');
      testError.name = 'LawbrokrTestError';
      throw testError;
    } catch (error) {
      reportError('Testing Honeybadger integration', error, {
        test: true,
        timestamp: Date.now(),
        component: 'test'
      });
    }
  }

  // Check if localStorage is available
  function isLocalStorageAvailable() {
    try {
      const test = '__storage_test__';
      localStorage.setItem(test, test);
      localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  }

  // -----------------------------------------
  // Parse query params and handle source inference
  // -----------------------------------------
  function getQueryParams() {
    try {
      let params = {};
      const searchParams = new URLSearchParams(window.location.search);
      let hasUtmSource = false;
      let inferredSource = '';

      searchParams.forEach((value, key) => {
        const normalizedKey = key.toLowerCase();
        if (config.trackAllParams || config.trackParams.includes(normalizedKey)) {
          if (value) {
            params[normalizedKey] = value;
            if (normalizedKey === 'utm_source') {
              hasUtmSource = true;
            }
            if (!inferredSource && SOURCE_MAPPING[normalizedKey]) {
              inferredSource = SOURCE_MAPPING[normalizedKey];
            }
          }
        }
      });

      if (!hasUtmSource && inferredSource) {
        params.utm_source = inferredSource;
        if (!params.utm_medium && inferredSource === 'google') {
          params.utm_medium = 'cpc';
        }
      }
      return params;
    } catch (err) {
      reportError('Error parsing query parameters', err, {
        search: window.location.search,
        trackAllParams: config.trackAllParams
      });
      return {};
    }
  }

  // -----------------------------------------
  // Merge existing + new params
  // -----------------------------------------
  function mergeParams(existing, incoming) {
    return { ...existing, ...incoming };
  }

  // -----------------------------------------
  // Save params to storage with cookie fallback
  // -----------------------------------------
  function saveParams(params) {
    if (!params || Object.keys(params).length === 0) {
      return;
    }

    const data = JSON.stringify(params);
    let success = false;

    if (isLocalStorageAvailable()) {
      try {
        localStorage.setItem(STORAGE_KEY, data);
        success = true;
      } catch (err) {
        reportError('Error saving to localStorage', err, {
          browserStorage: 'localStorage',
          dataSize: data.length,
          params: params
        });
      }
    }

    if (!success) {
      try {
        const expires = new Date();
        expires.setDate(expires.getDate() + COOKIE_CONFIG.days);
        
        const { path, domain, secure, sameSite } = COOKIE_CONFIG;
        const cookieString = `${STORAGE_KEY}=${encodeURIComponent(data)}; expires=${expires.toUTCString()}; path=${path}; domain=${domain}${secure ? '; secure' : ''}; samesite=${sameSite}`;
        
        document.cookie = cookieString;
      } catch (err) {
        reportError('Error saving to cookie', err, {
          browserStorage: 'cookie',
          dataSize: data.length,
          params: params,
          cookieConfig: COOKIE_CONFIG
        });
      }
    }
  }

  // -----------------------------------------
  // Load params from storage with cookie fallback
  // -----------------------------------------
  function loadParams() {
    let params = {};

    if (isLocalStorageAvailable()) {
      try {
        let stored = localStorage.getItem(STORAGE_KEY);
        if (stored) {
          params = JSON.parse(stored);
        }
      } catch (err) {
        reportError('Error loading from localStorage', err, {
          browserStorage: 'localStorage'
        });
      }
    }

    // If localStorage failed or was empty, try cookie
    if (Object.keys(params).length === 0) {
      try {
        let match = document.cookie.match(new RegExp('(^| )' + STORAGE_KEY + '=([^;]+)'));
        if (match) {
          params = JSON.parse(decodeURIComponent(match[2]));
        }
      } catch (err) {
        reportError('Error loading from cookie', err, {
          browserStorage: 'cookie',
          cookieString: document.cookie
        });
      }
    }

    return params;
  }

  // Helper function to check if a domain matches target domain
  const matchesDomain = (urlHostname, configDomain) => {
    urlHostname = urlHostname.toLowerCase();
    configDomain = configDomain.toLowerCase();
    
    // Handle wildcard domain pattern
    if (configDomain.startsWith('*.')) {
      const baseDomain = configDomain.slice(2); // Remove *. from the start
      const [subdomain, ...rest] = urlHostname.split('.');
      const urlBaseDomain = rest.join('.');
      
      // Don't match www or app subdomains
      if (subdomain === 'www' || subdomain === 'app') {
        return false;
      }
      
      return urlBaseDomain === baseDomain;
    }
    
    // Handle exact domain match (including subdomains)
    return urlHostname === configDomain || urlHostname.endsWith(`.${configDomain}`);
  };

  // -----------------------------------------
  // Check if a link's hostname ends with our domain
  // e.g. lawfirm.lawbrokr.com
  // -----------------------------------------
  function shouldAppendParams(link) {
    try {
      if (!link?.hostname || !config.domain) return false;
      return matchesDomain(link.hostname, config.domain);
    } catch (err) {
      reportError('Error checking hostname', err, {
        linkHostname: link?.hostname,
        configDomain: config.domain
      });
      return false;
    }
  }

  // -----------------------------------------
  // Append parameters to a link's existing query
  // -----------------------------------------
  function appendParams(link, params) {
    try {
      let url = new URL(link.href);
      let existingParams = new URLSearchParams(url.search);
      let updatedParams = new URLSearchParams('');
      let needsUpdate = false;

      // Copy all existing parameters
      existingParams.forEach(function(value, key) {
        updatedParams.append(key, value);
      });

      // Append our params only if they don't exist
      Object.keys(params).forEach(function(key) {
        let value = params[key];
        if (value !== undefined && value !== null && value !== '' && !existingParams.has(key)) {
          updatedParams.append(key, value);
          needsUpdate = true;
        }
      });

      // Add origin_url as the final parameter if it doesn't exist
      if (!existingParams.has('origin_url')) {
        let originUrl = window.location.origin + window.location.pathname;
        updatedParams.append('origin_url', originUrl);
        needsUpdate = true;
      }

      // Only update the URL if we actually need to add parameters
      if (needsUpdate) {
        isUpdatingLinks = true;
        url.search = updatedParams.toString();
        link.href = url.toString();
        // Reset flag after a short delay to allow mutation observer to process
        setTimeout(function() {
          isUpdatingLinks = false;
        }, 0);
      }
    } catch (err) {
      reportError('Error appending parameters to link', err, {
        linkHref: link?.href,
        params: params,
        isUpdatingLinks: isUpdatingLinks
      });
      isUpdatingLinks = false;
    }
  }

  // -----------------------------------------
  // Main logic to capture, merge, rewrite links
  // -----------------------------------------
  function forwardParams() {
    try {
      // Get current params from URL
      let currentParams = getQueryParams();
      let currentParamsExist = Object.keys(currentParams).length > 0;
      
      // Load previously stored params
      let storedParams = loadParams();
      
      // Merge with precedence to current params if they exist
      let merged = currentParamsExist ? 
        mergeParams(storedParams, currentParams) : 
        storedParams;
      
      // Only save if we have current params to prevent overwriting with empty values
      if (currentParamsExist) {
        saveParams(merged);
      }

      // Always process links, even if there are no stored/current params
      // This ensures origin_url is always added
      let allLinks = document.querySelectorAll('a[href]');
      Array.prototype.forEach.call(allLinks, function(link) {
        if (shouldAppendParams(link)) {
          appendParams(link, merged);
        }
      });
    } catch (err) {
      reportError('Error in forwardParams', err, {
        currentUrl: window.location.href,
        domain: config.domain,
        isUpdatingLinks: isUpdatingLinks
      });
    }
  }

  // -----------------------------------------
  // Public API to initialize Lawbrokr script
  // -----------------------------------------
  Lawbrokr.init = function(userConfig = {}) {
    config = { ...DEFAULT_CONFIG, ...userConfig };
    
    // Normalize trackParams if provided
    if (userConfig.trackParams) {
      config.trackParams = Array.isArray(userConfig.trackParams)
        ? userConfig.trackParams.map(p => p.toLowerCase())
        : DEFAULT_TRACK_PARAMS;
    }

    let isInitialized = false;
    let pendingTimeout = null;
    let observer = null;

    function handleForwardParams() {
      if (pendingTimeout) {
        clearTimeout(pendingTimeout);
      }
      pendingTimeout = setTimeout(function() {
        forwardParams();
        pendingTimeout = null;
      }, 250);
    }

    function cleanup() {
      try {
        if (observer) {
          observer.disconnect();
        }
        if (pendingTimeout) {
          clearTimeout(pendingTimeout);
        }
      } catch (err) {
        reportError('Error in cleanup function', err, {
          hasObserver: !!observer,
          hasPendingTimeout: !!pendingTimeout
        });
      }
    }

    function initForwarding() {
      // Prevent multiple initializations
      if (isInitialized) {
        return;
      }
      isInitialized = true;

      // Check for Honeybadger availability
      reportToHoneybadger = config.report_errors && window.Honeybadger;

      // Initial parameter forwarding
      forwardParams();

      // Set up mutation observer for dynamic content
      observer = new MutationObserver(function(mutations) {
        // Skip if we're currently updating links
        if (isUpdatingLinks) {
          return;
        }

        let shouldUpdate = false;
        try {
          shouldUpdate = mutations.some(function(mutation) {
            try {
              // For added nodes, check if any new links match our domain
              if (mutation.addedNodes.length > 0) {
                for (let i = 0; i < mutation.addedNodes.length; i++) {
                  let node = mutation.addedNodes[i];
                  // Check if the node itself is a matching link
                  if (node.nodeType === 1 && node.tagName === 'A' && shouldAppendParams(node)) {
                    return true;
                  }
                  // Check if the node contains any matching links
                  if (node.nodeType === 1 && node.getElementsByTagName) {
                    let links = node.getElementsByTagName('A');
                    for (let j = 0; j < links.length; j++) {
                      if (shouldAppendParams(links[j])) {
                        return true;
                      }
                    }
                  }
                }
              }
              
              // Only care about href changes on matching links
              if (mutation.type === 'attributes' && 
                  mutation.attributeName === 'href' && 
                  mutation.target.tagName === 'A' && 
                  shouldAppendParams(mutation.target)) {
                return true;
              }
              
              return false;
            } catch (err) {
              reportError('Error processing mutation', err, {
                mutationType: mutation.type,
                attributeName: mutation.attributeName,
                targetTagName: mutation.target?.tagName
              });
              return false;
            }
          });
        } catch (err) {
          reportError('Error processing mutations array', err, {
            mutationsLength: mutations?.length
          });
        }

        if (shouldUpdate) {
          handleForwardParams();
        }
      });

      // Start observing with a configuration that covers most use cases
      try {
        observer.observe(document.body, {
          childList: true,
          subtree: true,
          attributes: true,
          attributeFilter: ['href']
        });
      } catch (err) {
        reportError('Error setting up mutation observer', err, {
          bodyExists: !!document.body
        });
      }

      // Handle standard navigation events
      try {
        window.addEventListener('load', handleForwardParams);
        document.addEventListener('DOMContentLoaded', handleForwardParams);

        // jQuery check for WordPress/Squarespace support
        if (typeof window.jQuery !== 'undefined' && typeof window.jQuery.fn !== 'undefined' && typeof window.jQuery.fn.jquery !== 'undefined') {
          try {
            const jQueryInstance = window.jQuery;
            if (typeof jQueryInstance.fn.on === 'function') {
              jQueryInstance(document).on('ajaxComplete', handleForwardParams);
            }
          } catch (err) {
            console.warn('jQuery ajaxComplete handler not available', err);
          }
        }

        // Handle SPA support
        window.addEventListener('popstate', handleForwardParams);
        window.addEventListener('unload', cleanup);
      } catch (err) {
        reportError('Error setting up event listeners', err, {
          documentReadyState: document.readyState,
          hasJQuery: !!window.jQuery
        });
      }
    }

    // Initialize based on document state
    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', function() {
        setTimeout(initForwarding, 500);
      });
    } else {
      setTimeout(initForwarding, 500);
    }

    // Backup initialization for edge cases
    setTimeout(function() {
      if (!isInitialized) {
        initForwarding();
      }
    }, 2000);
  };

  // Add test function to public API
  Lawbrokr.testHoneybadger = testHoneybadger;

  global.Lawbrokr = Lawbrokr;

})(window);
